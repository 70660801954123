<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
import './assets/lib/rem.js'
import {enumList, version} from '@/api'
 export default {
   created() {
   },
   methods: {
   }
 }
</script>

<style>
html,body {-webkit-user-select:none; user-select: none;  font-size:.28rem;}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size:.28rem;
}
</style>
