import request from '@/util/request'
import { formData } from '@/util/util'
//聊天模块
//客户端创建新的匿名session
export function newSession() {
    return request({
        url: `/session/new`,
        method: 'post',
    })
}
//获取聊天记录
export function sessionList(session_id, maxChatLogId, size, current) {
    return request({
        url: `/session/chat-log?sessionId=${session_id}&maxChatLogId=${maxChatLogId}&size=${size}&current=${current}`,
        method: 'get',
    })
}
//获取问题
export function getCategory(category,size, current) {
    return request({
        url: `/qa/category?category=${category}&size=${size}&current=${current}`,
        method: 'get',
    })
}
//问题解决状态
export function getSolve(sessionId, messageId,isSolved) {
    return request({
        url: `/message/solved/${sessionId}/${messageId}?isSolved=${isSolved}`,
        method: 'post',
    })
}
//发送表单
export function formSubmit(sessionId,id,data) {
    return request({
        url: `/form/submit/${sessionId}/${id}`,
        method: 'post',
        data
    })
}
//上传病例
export function structuring(data) {
    return request({
        url: `/structuring/start`,
        method: 'post',
        data: formData(data),
    })
}
//刷新问题列表
export function messageRefresh(data) {
    return request({
        url: `/message/refresh`,
        method: 'post',
        data: data,
    })
}

//聊天跳转页面
export function messageClickLink(data) {
    return request({
        url: `/message/click_link`,
        method: 'post',
        data: data,
    })
}
//查看session的状态
export function sessionState(sessionId) {
    return request({
        url: `/session/state/${sessionId}`,
        method: 'GET',
    })
}
// 获取头像
export function sessionIcon(sessionId) {
    return request({
        url: `/session/client/icon/${sessionId}`,
        method: 'GET',
    })
}
//获取验证码
export function getUserSendcode(mobile) {
    return request({
        url: `/user/sendcode/${mobile}`,
        method: 'get',
    })
}
//登录
export function login(data) {
    return request({
        url: `/user/login?mobile=${data.mobile}&code=${data.code}`,
        method: 'post',
        data
    })
}
//绑定session
export function sessionBind(data) {
    return request({
        url: `/user/bind/session?session_id=${data.session_id}`,
        method: 'post',
        data
    })
}

//枚举
export function enumList(data) {
    return request({
        url: `/init/enum-list`,
        method: 'get',
    })
}
//
export function version(data) {
    return request({
        url: `init/enum-list/version`,
        method: 'get',
    })
}
//上传case基本信息
export function newCase(data) {
    return request({
        url: `/case/new`,
        method: 'post',
        data
    })
}
//修改基础信息
export function newCaseedit(data) {
    return request({
        url: `/case/edit`,
        method: 'post',
        data
    })
}
export function editDetailCase(data) {
    return request({
        url: `/case/detail/edit`,
        method: 'post',
        data
    })
}
//生日校验

export function checkBirthday(data) {
    return request({
        url: `/case/checkBirthday`,
        method: 'post',
        data
    })
}
//提交信息
export function caseSubmit(caseId) {
    return request({
        url: `/case/submit/${caseId}`,
        method: 'post',
    })
}
//获取caseId基本详情
export function getCase(caseId) {
    return request({
        url: `/case/get/${caseId}`,
        method: 'get',
    })
}
//获取caseId详细详情
export function getCaseDetail(caseId) {
    return request({
        url: `/case/detail/${caseId}`,
        method: 'get',
    })
}
//获取我的预核保列表
export function caseList() {
    return request({
        url: `/case/list`,
        method: 'get',
    })
}
//获取上传ocr的图片
export function ocrpicList(caseId) {
    return request({
        url: `/file/list/${caseId}`,
        method: 'get',
    })
}
//获取图片列表
export function categoryList(caseId) {
    return request({
        url: `/file/list/category/${caseId}`,
        method: 'get',
    })
}
//审核详情
export function listDetail(caseId) {
    return request({
        url: `/case/list-detail/${caseId}`,
        method: 'get',
    })
}
//随机问题
export function question() {
    return request({
        url: `/qa/random`,
        method: 'get',
    })
}
//上传图片获取oss签名
export function getSigned(caseId) {
    return request({
        url: `/file/getSigned/${caseId}`,
        method: 'get',
    })
}
//osss删除
export function ossImgdel(caseId, fileId) {
    return request({
        url: `/file/del/${caseId}/${fileId}`,
        method: 'delete',
    })
}
//删除上传失败的图片解决无法重复上传

export function delOssPath(caseId, fileName) {
    return request({
        url: `/file/del/oss/path/${caseId}?fileName=${fileName}`,
        method: 'delete',
    })
}
//查看pdf是否上传成功

export function pdfUploadOver(caseId) {
    return request({
        url: `/case/is-pdf-upload-over/${caseId}`,
        method: 'get',
    })
}
//预览pdf
export function pdfFiles(id) {
    return request({
        url: `/file/file/children/${id}`,
        method: 'get',
    })
}
//承保修改

export function passEdit(data) {
    return request({
        url: `/case/pass/edit`,
        method: 'post',
        data
    })
}
//立即投保
export function rgaInsureUrl(caseId) {
    return request({
        url: `/case/rga-insure-url/${caseId}`,
        method: 'get',
    })
}
//查姓名生日重复
export function nameBirthday(data) {
    return request({
        url: `/case/check-exist/name-birthday`,
        method: 'post',
        data
    })
}
//case审核记录
export function operationLogList(caseId) {
    return request({
        url: `/case/operation-log-list/${caseId}`,
        method: 'get',
    })
}

