import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [{
        path: '/caseUpload',
        name: 'caseUpload',
        meta: {
            title: "我的预核保"
        },
        component: () =>
            import ('@/views/home/index'),
    },
    {
        path: '/caseList',
        name: 'caseList',
        meta: {
            title: "我的预核保"
        },
        component: () =>
            import ('@/views/home/caseList'),
    },
    {
        path: '/previewPDF',
        name: 'previewPDF',
        meta: {
            title: "我的预核保"
        },
        component: () =>
            import ('@/views/home/previewPDF'),
    },
    {
        path: '/caseDetails',
        name: 'caseDetails',
        meta: {
            title: "我的预核保"
        },
        component: () =>
            import ('@/views/home/caseDetails'),
    },
    {
        path: '/caseImgs',
        name: 'caseImgs',
        meta: {
            title: "我的预核保"
        },
        component: () =>
            import ('@/views/home/caseImgs'),
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            title: "登录"
        },
        component: () =>
            import ('@/views/login/index'),
    },
    {
        path: '/service',
        name: 'service',
        meta: {
            title: "我的客服"
        },
        component: () =>
            import ('@/views/service/index'),
    },
    {
        path: '/aaa',
        name: 'service',
        meta: {
            title: "疾病方案"
        },
        component: () =>
            import ('@/views/service/diseaseKnowledge'),
    },
    {
        path: '/diseaseKnowledge',
        name: 'service',
        meta: {
            title: "疾病方案"
        },
        component: () =>
            import ('@/views/service/diseaseKnowledge1'),
    },
    {
        path: '/userAgreement',
        name: 'userAgreement',
        meta: {
            title: "用户协议"
        },
        component: () =>
            import ('@/views/agreement/userAgreement'),
    },
    {
        path: '/privacyAgreement',
        name: 'privacyAgreement',
        meta: {
            title: "隐私协议"
        },
        component: () =>
            import ('@/views/agreement/privacyAgreement'),
    },
    {
        path: '/',
        name: 'chat',
        meta: {
            title: "智能助理"
        },
        component: () =>
            import ('@/views/chat/index'),
    },
    {
        path: '/index',
        name: 'chat',
        meta: {
            title: "智能助理"
        },
        component: () =>
            import ('@/views/chat/index'),
    },
    {
        path: '/chatDetails',
        name: 'chatDetails',
        meta: {
            title: "智能助理"
        },
        component: () =>
            import ('@/views/chat/chat-details'),
    },
    {
        path: '/chatUpload',
        name: 'chat',
        meta: {
            title: "上传报告"
        },
        component: () =>
            import ('@/views/chat/chat-upload'),
    },
    {
        path: '/disease',
        name: 'disease',
        meta: {
            title: "疾病管理方案"
        },
        component: () =>
            import ('@/views/programme/disease'),
    },
    {
        path: '/programme',
        name: 'programme',
        meta: {
            title: "医疗费用保障方案"
        },
        component: () =>
            import ('@/views/programme/programme'),
    },
    {
        path: '/relevantCases',
        name: 'relevantCases',
        meta: {
            title: "相关案例"
        },
        component: () =>
            import ('@/views/programme/relevantCases'),
    },
    {
        path: '/tubercle',
        name: 'tubercle',
        meta: {
            title: "相关案例"
        },
        component: () =>
            import ('@/views/programme/tubercle'),
    },
    {
        path: '/QRcode',
        name: 'QRcode',
        meta: {
            title: "联系我们"
        },
        component: () =>
            import ('@/views/programme/QRcode'),
    },

]

const router = new VueRouter({
    routes,
})

router.beforeEach(async(to, from, next) => {
    document.title = to.meta.title
    // if(!store.getters.init){
    //     store.dispatch('enumList')
    // }
    next()
})

export default router