import Cookies from 'js-cookie'

// const TokenKey = 'USER-TOKEN'

//cookie
export function getCookie(Key) {
  return JSON.parse(Cookies.get(Key) || JSON.stringify(''))
}

export function setCookie(Key, token) {
  return Cookies.set(Key, JSON.stringify(token))
}

export function removeCookie(Key) {
  return Cookies.remove(Key)
}

//路由标题
const title = '壹智保'
export function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}
//实现递归深拷贝
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}

//formData
export function formData(query) {
  let form_data = new FormData()
  for (let i = 0; i < Object.keys(query).length; i++) {
    if (Object.values(query)[i] instanceof Array) {
      for (let k = 0; k < Object.values(query)[i].length; k++) {
        // form_data.append(Object.keys(query)[i] + '[' + k + ']', Object.values(query)[i][k]);
        form_data.append(Object.keys(query)[i], Object.values(query)[i][k])
      }
    } else {
      form_data.append(Object.keys(query)[i], Object.values(query)[i])
    }
  }
  return form_data
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
