import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant, { Toast } from 'vant'
import 'vant/lib/index.css'
import './assets/scss/index.scss'
import './icons' // icon
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import iView from 'iview';
import 'iview/dist/styles/iview.css';
import FastClick from 'fastclick'
FastClick.attach(document.body);
// import VueWebsocket from "vue-websocket";
// Vue.use(VueWebsocket);
Toast.setDefaultOptions('loading', { forbidClick: true })
Vue.use(Vant)
Vue.use(Toast)
Vue.use(iView);
Vue.use(ElementUI);


Vue.config.productionTip = false

Vue.mixin({
    mounted: function() {
        // if (this.$route) {
        //   if (this.$route.query.token) {
        //     this.$store.commit('SET_TOKEN', this.$route.query.token)
        //   }
        // }
    },
})

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')