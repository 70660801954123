import axios from 'axios'
import Vue from 'vue'
import Router from '../router'
import { Toast } from 'vant'
import { removeCookie } from '@/util/util'

Vue.use(Toast)
    // import { getCookie } from '@/util/util.js'
import store from '@/store'
const service = axios.create({
    baseURL: process.env.VUE_APP_API, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    headers: {
        'Content-Type': 'application/json;charset=utf-8',
    },
    timeout: 0, // request timeout
})
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        if (store.getters.token) {
            config.headers['token'] = store.getters.token
        }
        return config
    },
    (error) => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

service.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code != 200 && res.code != 2000) {
            if (res.code == 307 ||res.code == 6205 ) {
                // Message({
                //   message: res.message || 'Error',
                //   type: 'error',
                //   duration: 3 * 1000,
                // })　
                store.commit('SET_TOKEN','')
                removeCookie('token')
                Router.push('/login')

            }
            // if (res.code == 6205) {
            //     Toast.fail(res.message || error || '请求失败，请稍后重试')
            //     removeCookie('token')
            //     Router.push({path:'/login'})
            // }

            Toast.fail(res.message || error || '请求失败，请稍后重试')
            return Promise.reject(res.message || 'Error')
        } else {
            return Promise.resolve(res.result)
        }
    },
    (error) => {
        console.log('err' + error) // for debug
        Toast.fail(res.message || error || '请求失败，请稍后重试')
        return Promise.reject(error)
    }
)

export default service